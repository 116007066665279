export const ADULT_CONTENT_COOKIE = 'isAdult';
export const ANALYTICS_COOKIE_NAME = 'auuid';
export const ATA_SID = 'ata_SID';
export const CSRF_TOKEN = '_csrfToken';
export const HIDE_AGE_COMPLIANCE_COOKIE = 'hideAgeCompliance';
export const HIDE_FREE_ACCESS_GATEWAY_COOKIE = 'hideFreeAccessGateway';
export const HIDE_FREE_SITES_BUTTON = 'hideFreeSitesButton';
export const HIDE_GUEST_GATEWAY_COOKIE = 'hideGuestGateway';
export const HIDE_UPGRADE_STRIP_COOKIE = 'hideUpgradeStrip';
export const IS_TRANSLATED_COOKIE = 'browser-translated';
export const MAM_COOKIE_NAME = 'mam';
export const MAM_IDENT_COOKIE = 'mamident';
export const OPTANON_CONSENT_CLOSED_COOKIE = 'OptanonAlertBoxClosed';
export const OPTANON_CONSENT_COOKIE = 'OptanonConsent';
export const PARTNER_PROMO_BANNERS_COOKIE = 'showPartnerBanners';
export const PREVIEW_TRACKER_COOKIE = 'movieSlideshowSeen';
export const RECENT_HISTORY_COOKIE = 'history';
export const RECENT_ITEMS_COOKIE = 'recents';
export const RESUMABLE_VIDEOS_COOKIE = 'resumableVideos';
export const SEARCH_SORT_COOKIE = 'searchSort';

export const getAffiliateCookies = (siteAbbreviation?: string) => [
  MAM_COOKIE_NAME,
  MAM_IDENT_COOKIE,
  ATA_SID,
  `${siteAbbreviation}_PA`,
];
