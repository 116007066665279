import React, { useCallback, useState } from 'react';
import { useSelector } from '../../redux';
import GuestGatewayModal from './GuestGatewayModal';
import { FreeTrialDialog } from './FreeTrialDialog';
import FreeTrialScrollToTop from './FreeTrialScrollToTop';
import { User } from 'src/@types/app';
import { usePopupBanners } from './usePopupBanners';
import { AgeVerifyModal } from 'src/client/components/modals/AgeVerifyModal';
import { HIDE_GUEST_GATEWAY_COOKIE } from 'src/shared/constants/cookies';

const useFreeTrialDialogHook = (user?: User) => {
  const { test } = useSelector((state) => state.app);
  const [isFreeTrialDialogOpen, setFreeTrialDialogOpen] = useState(false);

  const openFreeTrialDialog = useCallback(
    (open) => {
      if (!user && test?.testId) {
        window.VWO = window.VWO || [];
        window.VWO.push(['activate', false, [test.testId], true]);
      }
      setFreeTrialDialogOpen(open);
    },
    [test, user],
  );

  const closeFreeTrialDialog = () => {
    window.dataLayer.push({
      event: 'gaEvent',
      eventCategory: 'free-trial',
      eventLabel: 'dialog',
      eventAction: 'hide',
    });
    setFreeTrialDialogOpen(false);
  };

  return {
    isFreeTrialDialogOpen,
    openFreeTrialDialog,
    closeFreeTrialDialog,
  };
};

export const PopupBanners = () => {
  const { user } = useSelector((state) => state.auth);
  const { featureFlags } = useSelector((state) => state.app.config);

  const [activeModal, closeActiveModal] = usePopupBanners();
  const { isFreeTrialDialogOpen, openFreeTrialDialog, closeFreeTrialDialog } = useFreeTrialDialogHook(user);

  const shouldRenderGuestGatewayModal = activeModal === 'showGuestGateway';
  const shouldShowAgeVerifyBanner = Boolean(
    featureFlags?.enableEmblemAgeVerification &&
      (user?.hasPPSPurchase || user?.hasAnyValidSubscription) &&
      user?.accountNeedsAgeVerification,
  );

  if (shouldShowAgeVerifyBanner) {
    return <AgeVerifyModal />;
  }

  return (
    <>
      <FreeTrialScrollToTop setFreeTrialDialogOpen={openFreeTrialDialog} />

      {shouldRenderGuestGatewayModal && <GuestGatewayModal onClose={closeActiveModal(HIDE_GUEST_GATEWAY_COOKIE)} />}

      <FreeTrialDialog show={isFreeTrialDialogOpen} onClose={closeFreeTrialDialog} />
    </>
  );
};
